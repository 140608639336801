<template>
  <custom-vue-select
    id="azkar-type"
    label="Type"
    text-field="name"
    placeholder="Select type"
    :rules="rules"
    :multiple="$attrs.multiple"
    :value-reducer="option=>option"
    :options="addNew === false ? types : typesOptions"
    :selected.sync="typeProxy"
    :disabled="$attrs.disabled"
  />
</template>
<script>
import CustomVueSelect from '@/common/components/common/FormInputs/CustomVueSelect.vue'

export default {
  name: 'AzkarTypeSelect',
  components: {
    CustomVueSelect,
  },
  props: {
    type: { type: [Array, Object], default: () => {} },
    filter: { type: Object, default: () => {} },
    rules: { type: String, default: '' },
    addNew: { type: Boolean, default: () => false },
  },
  data() {
    return {
      types: [{ name: 'All', value: null }, { name: 'Azkar', value: 'Azkar' }, { name: 'Duaa', value: 'Duaa' }],
      typesOptions: [{ name: 'Azkar', value: 'Azkar' }, { name: 'Duaa', value: 'Duaa' }],
    }
  },
  computed: {
    typeProxy: {
      get() {
        return this.type
      },
      set(value) {
        this.$emit('update:type', value)
      },
    },
  },
  setup() {
  },
  created() {
  },
  methods: {
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
